import { useState, useEffect } from "react";

const useCookie = (cookieName : string, defaultValue : string) => {
  const [cookieValue, setCookieValue] = useState(defaultValue);

  useEffect(() => {
    const cookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${cookieName}=`));

    setCookieValue(cookie ? cookie.split("=")[1] : "");
  }, [cookieName]);

  const setCookie = (value : string, expirationDate : Date) => {
    document.cookie = `${cookieName}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
    setCookieValue(value)
  };

  const deleteCookie = () => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    setCookieValue("")
  };

  return {cookieValue, setCookie, deleteCookie};
};


export default useCookie;