import { useContext, useState } from "react";
import { AppContext } from "../AppContext";
import TourSeasonResults from "../components/pages/TourSeasonResults";
import { Nav } from "react-bootstrap";

import './ResultPage.css'
import ScoringStatsLeaderboard from "../components/pages/ScoringStatsLeaderboard";
import PlacementStatLeaderboard from "../components/pages/PlacementStatLeaderboard";

export default function ResultPage() {

    const context = useContext(AppContext);

    const [activeKey, setActiveKey] = useState("1");

    const onSelectNav = (key : any)  => {
        setActiveKey(key);
    }

    let component = <></>

    switch(activeKey) {
        case "1" :
            component = <TourSeasonResults tourSeason={context.tourSeason} />
            break;
        case "2" :
            component = <PlacementStatLeaderboard tourSeason={context.tourSeason} />
            break;
        case "3" :
            component = <ScoringStatsLeaderboard tourSeason={context.tourSeason} />
            break;
        default:
            component = <></>
            break; 
    }

    return (
        <>
            <div className="result-page-menu">
                <Nav justify variant="tabs" activeKey={activeKey} onSelect={onSelectNav}>
                    <Nav.Item>
                        <Nav.Link eventKey="1">
                            Omgångar
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="2" >
                            Placering
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="3">
                            Birdieligan
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
            {
                component
            }
            
        </>
    )
}