import { useContext, useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Modal, Row } from "react-bootstrap";
import XfTable, { TXfColumn, XfCellType } from "../XfTable/XfTable";
import { Trash } from "react-bootstrap-icons";
import { AppContext } from "../../AppContext";
import AddRoundDialog from "./AddRoundDialog";
import { useEventApi, Event, updateEventApi } from "../../api/EventApi";
import { updateParticipantsApi, useParticipantApi } from "../../api/ParticipantApi";
import { dateOnly } from "../../utils/Formatting";
import EditEvent from "./EditEvent";
import { confirmDelete } from "../XfConfirmation";

export default function AdminEvents() {
    const context = useContext(AppContext);
    const {data, setData, isLoading, hasError, error, setUrl, refreshData, doCreate, doUpdate, doDelete} = useEventApi(context.tourSeason.tourSeasonId);
    const {data : participants, setUrl : setUrlParticipants} = useParticipantApi(context.tourSeason.tourSeasonId)

    const [showEvents, setShowEvents] = useState(Array<boolean>())

    const [newEventId, setNewEventId] = useState(-1)

    useEffect( () => {
        updateEventApi(setUrl, context.tourSeason.tourSeasonId);
        updateParticipantsApi(setUrlParticipants, context.tourSeason.tourSeasonId)
    }, [context.tourSeason.tourSeasonId])

    const addRound = () => { setShowDlg(true) }

    const [showDlg, setShowDlg] = useState(false)
    const onDlgSave = (date : string, name : string) => {
        const id = newEventId - 1;
        setNewEventId(id)
        // TODO: REMOVE HARDCODED courseID
        doCreate({eventId : id, date : date, name : name, courseId : 1, results : []})
        setShowDlg(false) 
    }
    const onDlgClose = () => { setShowDlg(false) }

    const deleteEvent = ( async (eventId : number) => {
        if(await confirmDelete("Radera omgång?")) {
            doDelete(eventId);
        }
    })

    const events = data.sort( (a : Event, b : Event) =>  -1 * a.date.localeCompare(b.date))

    const expandSection = (idx : number) => {
        const newShowEvents = events.map( (e, i) => i == idx ? true : false)
        setShowEvents(newShowEvents)
    }

    return (
        <div>
            <AddRoundDialog 
                show = {showDlg}
                onSave={onDlgSave}
                onClose={onDlgClose}
            />
            <h2>Redigera omgångar</h2>
            <div><Button onClick={addRound}>Ny omgång</Button></div>
                <div>
                 <Accordion>
                 {
                     events ? 
                         events.map( (e, idx) => 
                             <Accordion.Item key={e.eventId} eventKey={idx.toString()}>
                                <Accordion.Header>{dateOnly(e.date)} {e.name}</Accordion.Header>
                                <Accordion.Body onEnter={ () => expandSection(idx) }    >
                                    {
                                        showEvents.length > idx ? 
                                            showEvents[idx] == true ? <EditEvent eventId={e.eventId} participants={participants}/> : ""
                                            : ""
                                    }
                                    <Button variant="danger" onClick={ () => deleteEvent(e.eventId)}>Ta bort omgång</Button>
                                </Accordion.Body>
                             </Accordion.Item>
                         )
                     : 
                         null
                 }
                 </Accordion>

             </div>

        </div>

    )
}
