import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

type TAddRoundDialogProps = {
    show : boolean
    onSave : (date : string, name : string) => void
    onClose : () => void
}

export default function AddRoundDialog(props : TAddRoundDialogProps) {

    const [show, setShow] = useState<boolean>(false)
    const [date, setDate] = useState<string>("2024-01-01")
    const [name, setName] = useState<string>("")

    const onClose = () => props.onClose()

    const onSave = () => {
        props.onSave(date, name)
    }

    useEffect( () => {
        setShow(props.show)
    }, [props.show])


    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Lägg till omgång</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Datum</Form.Label>
                        <Form.Control type="text" value={date} onChange={ (e) => setDate(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Beskrivning</Form.Label>
                        <Form.Control type="text" value={name} onChange={ (e) => setName(e.target.value)} />
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Avbryt</Button>
                <Button variant="primary" onClick={onSave}>Lägg till</Button>
            </Modal.Footer>
        </Modal>
    )
}