import { useContext } from "react";
import Leaderboard from "../components/pages/Leaderboard";
import { AppContext } from "../AppContext";
import { useParams } from "react-router-dom";
import ParticipantStats from "../components/pages/ParticipantStats";


export default function ParticipantPage() {

    const context = useContext(AppContext);

    const params = useParams();
    const participantId : number = +params.participantId!
    
    return (
        <>
            <ParticipantStats participantId={participantId} tourSeasonId={context.tourSeason.tourSeasonId}/>
        </>
    )
}