import React, { createContext, useEffect, useMemo, useState } from "react";
import { TourSeason, useTourSeasonApi } from "./api/TourSeasonApi";
import useCookie from "./utils/useCookie";

//
//
//
//
export type TAppContext = {
    playerId : string,
    logout: () => void,
    login: (playerId : number) => void,
    tourId : number,
    selectTourSeason : (tourSeasonId : number) => void,
    tourSeason : TourSeason
}

const defaultContext = {    playerId : "", 
                            seasonId : 0, 
                            tourId : 1,
                            logout : () => null, 
                            login : (playerId : number) => null, 
                            selectTourSeason : (tourSeasonId : number) => null,
                            tourSeason : {tourSeasonId : 0, name : "", shortName: "", tourId :  0, year:0, sortOrder:1, active:0, gameTypeId : 0, hcpReduction : 1}}

//
//
//
export const AppContext = createContext<TAppContext>(defaultContext);

//
//
//
export const AppContextProvider: React.FC<{children: React.ReactNode}> = (children) => {
    const {cookieValue: playerId, setCookie : setPlayerId, deleteCookie : deletePlayerId} = useCookie("user", "0") //useState<number>(0)
//    const {cookieValue: tourSeasonId, setCookie : setTourSeasonId, deleteCookie : deleteTourSeasonId} = useCookie("tourSeasonId", "0") //useState<number>(0)
    const [tourSeasonId, setTourSeasonId] = useState<number>(0)
    const [tourSeason, setTourSeason] = useState<TourSeason>(defaultContext.tourSeason) 
    const {data : tourSeasons} = useTourSeasonApi();
    const [tourId, setTourId] = useState<number>(0)

    // Set tourSeason when data is loaded - Default is last for current year ()
    useEffect( () => {
        const year = new Date().getFullYear()
        if(tourSeasons.length > 0) {
            const currentTs = tourSeasons.filter( (ts) => ts.active === 1 && ts.year == year)
            if(currentTs.length > 0) {
                const tsId = currentTs.sort( (a,b) => b.sortOrder - a.sortOrder)[0]
                selectTourSeasonId(tsId.tourSeasonId)
            } else {
                const tsId =  tourSeasons.sort( (a,b) => b.sortOrder - a.sortOrder)[0]
                selectTourSeasonId(tsId.tourSeasonId)
            }
        } 
    }, [tourSeasons])


    const logout = () => {
        deletePlayerId(); 
    }

    const login = (pid : number) => {
        let expDate = new Date()
        expDate.setDate(expDate.getDate() + 1800)
        setPlayerId(pid.toString(), expDate )
    }

    const selectTourSeasonId = (tourSeasonId : number) => {

        let expDate = new Date()
        expDate.setDate(expDate.getDate() + 1800)
        const ts = tourSeasons.find( (ts) => ts.tourSeasonId === tourSeasonId)
        setTourSeason(ts!)
        setTourSeasonId(tourSeasonId)
    }

    return (
        <AppContext.Provider value={{   playerId : playerId, 
                                        tourId : tourId,
                                        logout : logout, 
                                        login : login, 
                                        selectTourSeason : selectTourSeasonId,
                                        tourSeason : tourSeason}}>
            {children.children}
        </AppContext.Provider>
    );
};