import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { doLoginApi } from "../../api/LoginApi";

type TLoginDialogProps = {
    show : boolean
    onSuccess : (playerId : number) => void
    onClose : () => void
}

export default function LoginDialog(props : TLoginDialogProps) {

    const [show, setShow] = useState<boolean>(false)
    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [errorMessage, setErrorMessage] = useState<string>("")

    const onClose = () => props.onClose()

    const onLogin = async () => {
        const data = await doLoginApi(username, password)

        var isSuccess = false

        if(data!.isSuccessful) {
            props.onSuccess(data!.playerId!)
            isSuccess = true
        }

        if(!isSuccess) setErrorMessage("Inloggning misslyckades, försök igen.")

    }

    useEffect( () => {
        setShow(props.show)
        setErrorMessage("")
        setUsername("")
        setPassword("")
    }, [props.show])

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Logga in</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Användare</Form.Label>
                        <Form.Control type="text" value={username} onChange={ (e) => setUsername(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Lösenord</Form.Label>
                        <Form.Control type="text" value={password} onChange={ (e) => setPassword(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3 error-message">
                        <Form.Label>{errorMessage}</Form.Label>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={onLogin}>Logga in</Button>
                <Button variant="secondary" onClick={onClose}>Avbryt</Button>
            </Modal.Footer>
        </Modal>
    )
}