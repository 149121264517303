import { Form } from "react-bootstrap"
import { Participant } from "../../api/ParticipantApi"

interface IParticipantSelectorProps {
    onSelectParticipant : (participantId : number) => void
    selectedParticipant : number | undefined
    participantList : Array<Participant>
}

export default function ParticipantSelector(props : IParticipantSelectorProps) {

    const onChange = (e : React.ChangeEvent<HTMLSelectElement>) => {
        const id : number = +e.target.value;
        props.onSelectParticipant(id)
    }

    return (
        <Form.Select value={props.selectedParticipant} className="border-0" onChange={ onChange }>
            <option value={-1}>Välj spelare</option>
            {
                props.participantList.map(p => 
                    <option key={p.participantId} value={p.participantId}>{p.playerName}</option>
                )
            }
        </Form.Select>
    )
}