import { doPostRequest} from "./api";
import { LoginResponse, LoginRequest } from "./apimodel";

export type { LoginResponse as Login} from "./apimodel";

export async function doLoginApi(username : string, password : string) {
    const url = `/login`;
    const data : LoginRequest = { username : username, password : password}
    return await doPostRequest<LoginResponse, LoginRequest>(url, data);
}

