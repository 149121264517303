import { useContext } from "react";
import Leaderboard from "../components/pages/Leaderboard";
import { AppContext } from "../AppContext";


export default function LeaderboardPage() {

    const context = useContext(AppContext);


    return (
        <div>
            <h4>{context.tourSeason.name}</h4>
            <Leaderboard tourSeason={context.tourSeason} tourSeasonId={context.tourSeason.tourSeasonId}/>
        </div>
    )
}