import { Outlet } from "react-router-dom";
import AppMenu from "./AppMenu";
import { Container } from "react-bootstrap";
import { AppContextProvider } from "./AppContext";
import './App.css'

export default function App() {

    const testFlag = process.env.REACT_APP_TESTFLAG 

    return (
        <>
            <AppContextProvider>
                <AppMenu />
                <Container className="main-content">
                    <Outlet />
                </Container>
                <div className="footer">
                    <div className="footer-container">
                        { testFlag }
                        <a href="http://lagk.se/" target="_blank"><img src="assets/adv/lagk.png" /></a>
                        <a href="https://sofogolf.com/"><img src="assets/adv/sofow.png" /></a>
                        <a href="https://ruffgolf.se/indoor-golf/ruff-lund/"><img className="footer-ruff" src="assets/adv/ruff.png" /></a>
                        <a href="https://pinecoastgolf.com/"><img src="assets/adv/pinecoastw.png" /></a>
                    </div>
                </div>
            </AppContextProvider>

        </>
    )
}