import React, { useContext, useState } from "react"
import { Button, Container } from "react-bootstrap"
import { Nav } from "react-bootstrap"
import { Navbar } from "react-bootstrap"
import { NavDropdown } from "react-bootstrap"
import { Link } from "react-router-dom"
import { AppContext } from "./AppContext"
import LoginDialog from "./components/pages/LoginDialog"
import { BoxArrowInRight } from "react-bootstrap-icons"
import { TourSeason, useTourSeasonApi } from "./api/TourSeasonApi"

//
//  TourSeason-selector
//

interface ITourSeasonSelector {
    currentTourSeasonId : number
    tourSeasons : Array<TourSeason>
    onSelectTourSeason : (tourSeasonId : number) => void
}

function TourSeasonSelector(props : ITourSeasonSelector) {

    const ts = props.tourSeasons.find( (ts) => ts.tourSeasonId === props.currentTourSeasonId)
    const shortName = ts ? ts.shortName : ""
    const tourSeasons = props.tourSeasons.sort( (a, b) => b.sortOrder - a.sortOrder )


    return (
        <NavDropdown title={shortName} id="season" onSelect={ (key) => props.onSelectTourSeason(+key!)}>
            {
                tourSeasons.map( (ts) => 
                    <NavDropdown.Item key={ts.tourSeasonId} eventKey={ts.tourSeasonId}>{ts.shortName}</NavDropdown.Item>                                            
                )
            }
        </NavDropdown>
    )
}


type AppMenuProps = {

}

export default function AppMenu(props: AppMenuProps) {

    const [showLogin, setShowLogin] = useState<boolean>(false)
    const {data : tourSeasons} = useTourSeasonApi();
    const {playerId, login, logout, tourSeason, selectTourSeason} = useContext(AppContext);

    const isLoggedIn = playerId === "" ? false : true;

    const showLoginDlg = () => setShowLogin(true)
    const hideLoginDlg = () => setShowLogin(false)

    const doLogin = (playerId : number) => {

        login(playerId)
        hideLoginDlg()
    }

    return(
        <>
            <LoginDialog show={showLogin} onSuccess={doLogin} onClose={hideLoginDlg} />
            <Navbar className="menu-bar">
                <Container>
                    <Navbar.Brand href="/">
                        <img className="brand-image" src="/assets/logo_white.svg" />
                    </Navbar.Brand> 
                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className="text-white" id="basic-navbar-nav"> */}
                        <Nav className="ms-auto text-white">
                            {
                                isLoggedIn ? 
                                <>
                                    <TourSeasonSelector currentTourSeasonId={tourSeason.tourSeasonId} tourSeasons={tourSeasons} onSelectTourSeason={selectTourSeason} />
                                    <Nav.Link eventKey="leaderboard" as={Link} to="leaderboard">Leaderboard</Nav.Link>
                                    <Nav.Link eventKey="result" as={Link} to="results">Resultat</Nav.Link>
                                    <Nav.Link eventKey="about" as={Link} to="about">Info</Nav.Link>
                                    {/* <Nav.Link eventKey="stats" as={Link} to="stats">Statistik</Nav.Link> */}
                                    <Nav.Link eventKey="adminrounds" as={Link} to="adminrounds">Redigera omg</Nav.Link>
                                    <Nav.Link eventKey="admin" as={Link} to="admin">Admin</Nav.Link>
                                    <Nav.Link eventKey="logout" onClick={logout}>Logout</Nav.Link>
                                </>
                                :
                                <> 
                                    <TourSeasonSelector currentTourSeasonId={tourSeason.tourSeasonId} tourSeasons={tourSeasons} onSelectTourSeason={selectTourSeason} />

                                    <Nav.Link eventKey="leaderboard" as={Link} to="leaderboard">Leaderboard</Nav.Link>
                                    <Nav.Link eventKey="result" as={Link} to="results">Resultat</Nav.Link>
                                    <Nav.Link eventKey="about" as={Link} to="about">Info</Nav.Link>
                                    <Nav.Link className="show-on-web"   eventKey="login" onClick={showLoginDlg}>Login</Nav.Link>
                                    <Nav.Link className="show-on-mobile" eventKey="login" onClick={showLoginDlg}><BoxArrowInRight /></Nav.Link>
                                </>
                            }
                        </Nav>
                    {/* </Navbar.Collapse> */}
                </Container>
            </Navbar>
        </>

    )

}

