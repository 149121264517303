import { StatisticTable } from "./ViewRound";
import { useCourseApi } from "../../api/CourseApi";
import { useHoleByHoleApi } from "../../api/HoleByHoleApi";
import { TourSeason } from "../../api/TourSeasonApi";

export interface IStatsHoleByHole {
    tourSeason : TourSeason
}

export default function StatsHoleByHole(props : IStatsHoleByHole) {  

    const {data, setData, isLoading, hasError, error, setUrl, refreshData} = useHoleByHoleApi(props.tourSeason.tourSeasonId);
    const { data : course } = useCourseApi(1); 

    const holes = course ? (course.length > 0 ? course[0].holes.sort( (a,b) => a.holeNo - b.holeNo) : []) : []
    const holePar : Array<number> = holes.map( h => h.par)

    const names = ["Snitt", "Diff", "Eagle", "Birdie", "Par", "Bogey", "D.Bog", "Sämre"]

    const sortedData = data.sort( (a, b) => a.holeNo - b.holeNo)

    const scores = names.map( (n) => {

        switch(n) {
            case "Snitt" :
                return sortedData.map( (d) => d.avgScore)
            case "Diff" : 
                return sortedData.map( (d) => d.avgToPar)
            case "Eagle" : 
                return sortedData.map( (d) => d.eagles)
            case "Birdie" : 
                return sortedData.map( (d) => d.birdies)
            case "Par" : 
                return sortedData.map( (d) => d.pars)
            case "Bogey" : 
                return sortedData.map( (d) => d.bogeys)
            case "D.Bog" : 
                return sortedData.map( (d) => d.dblbogeys)
            case "Sämre" : 
                return sortedData.map( (d) => d.worse)
            default:
                return []
        }
    })

    return (
        <>
            <div className="player-stat-eclictic-table leaderboard-table">
                <StatisticTable scores={scores} names={names} holePar={holePar} />
            </div>
        </>
    )
}