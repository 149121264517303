import { Button, Modal } from "react-bootstrap"
import { confirmable, createConfirmation } from "react-confirm"
import { getTextOfJSDocComment } from "typescript"

type TXfConfirmationProps = {
  show : boolean,
  proceed : (answer : boolean) => void,
  confirmation : string,
  options : any
}

function XfDeleteConfirmation(props : any) {

  return (
    <div className="static-modal">
      <Modal
        animation={false}
        show={props.show}
        onHide={() => props.proceed(false)}
        backdrop={true}
        keyboard={true}
      >
        <Modal.Header>
          <Modal.Title>"Radera?"</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.confirmation}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => props.proceed(false)}>Avbryt</Button>
          <Button onClick={() => props.proceed(true)}>Ok</Button>
        </Modal.Footer>
      </Modal>
    </div>   
  )
}

const confirmableDialog = confirmable(XfDeleteConfirmation)

const confirmDeleteDlg = createConfirmation(confirmableDialog)

export function confirmDelete(confirmation : string) {
  return confirmDeleteDlg({confirmation})
}