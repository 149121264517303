/*
 *  Custom table component
 *
 * 
 */
import { Component, useState } from "react"
import { Form, Table } from "react-bootstrap"

// TODO: Split in files (types, cells, table)


/**
 * 
 * Definitions
 * 
 */
// Variants of cells
export enum XfCellType {
    text = 1,
    dropdown = 2
}

// Definition of Column
export type TXfColumn = {
    field? : string,
    name : string,
    icon? : any,
    onClick? : (row : any) => void,
    hidden? : boolean,
    cellType? : XfCellType,
    valueList? : any,
    minWidth? : number,
    format? : (value : any, row : any) => void
}

// 
//  Key - value definition of a dropdown list 
//
type XfCellValueListItem = {
    id : string,
    value : string
}

/**
 * 
 * Table Cell
 * 
 */
interface IXfCellProps  {
    column : TXfColumn,
    row : any,
    editable : boolean,
    cellType? : XfCellType,
    valueList? : Array<XfCellValueListItem>
    minWidth? : number,
    onCellChange? : (row : any, column : any, value : any) => void
}

function XfCell(props : IXfCellProps) {

    // Manage default values
    const celltype : XfCellType = props.cellType == undefined ? XfCellType.text : props.cellType;

    const onClick = props.column.onClick;

    var tdStyle : any = props.column.onClick ? {cursor:"pointer"} : {}

    var idx = 0;

    var columnContent = null;
    const showValue = props.column.field ? true : false
    const value = showValue ? (props.row != null ? props.row[props.column.field!] : "") : ""
    
    const changeCell = (e : any) => {
        if (props.onCellChange != undefined) 
            props.onCellChange(props.row, props.column, e.target.value)
    }

    if (showValue) {
        if(props.editable) {
            switch(celltype) {
                case XfCellType.text : 
                    columnContent = <Form.Control type="text" value={value} onChange={(e) => changeCell(e)} ></Form.Control>
                    break;
                case XfCellType.dropdown:
                    columnContent = <Form.Select value={value} onChange={(e) => changeCell(e)}>
                                        <option key={0}>välj spelare...</option>
                                        {
                                            props.valueList!.map(v => 
                                                <option key={v.id} value={v.id}>{v.value}</option>
                                            )
                                        }
                                    </Form.Select>
                    break;

            }

        } else {
            if(props.column.format != undefined) {
                columnContent = props.column.format(value, props.row);
            } else {
                columnContent = value
            }
        }    
    }

    if (props.minWidth != undefined)
        tdStyle = { minWidth : props.minWidth, ...tdStyle}

    return (
        <>
            <td key={idx++}
                onClick={() => onClick ? onClick(props.row) : null } 
                style={tdStyle}
            
            >
                {
                    props.column.icon ? props.column.icon : null
                }
                { columnContent }
            </td>
        </>
    )
}

/**
 * 
 * Table - editable table for CRUD management of data
 * 
 */
export interface IXfTableProps  {
    data : Array<any>
    columns : Array<TXfColumn>
    useEmptyRowToAdd? : boolean
    editable? : boolean,
    onChangeCell? : (row : any, column : any, value : any) => void
}

export default function XfTable(props : IXfTableProps) {
    
    var idx = 0;

    // Manage default value for all optional props
    const useEmptyRowToAdd : boolean = props.useEmptyRowToAdd == undefined ? false : props.useEmptyRowToAdd;
    const editable : boolean = props.editable == undefined ? false : props.editable

    // Non optional props
    const columns = props.columns
    const data = props.data

    return (
            <Table>
                <thead>
                    <tr>
                        {
                            columns.map(c => {
                                return (
                                    <td key={idx++}>
                                        { c.name }
                                    </td>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                        {
                            data.map(r => {
                                return (
                                    <tr key={idx++}>
                                        {
                                            columns.map(c => {
                                                return (
                                                    <XfCell key={idx++} column={c} row={r} editable={editable} onCellChange={props.onChangeCell} { ...c }/>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                        {
                            useEmptyRowToAdd ? 
                                <tr key={idx}>
                                    {
                                        columns.map(c => {
                                                return (
                                                        <XfCell key={idx++} column={c} row={null} editable={editable} onCellChange={props.onChangeCell} {...c}/>
                                                )
                                        })
                                    }
                                </tr>
                            : null                    
                        }
                </tbody>
            </Table>
    )
}