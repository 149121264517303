

import { Button, Collapse, Container, Table } from 'react-bootstrap';
import { useParticipantApi } from '../../api/ParticipantApi';
import { TourSeason } from '../../api/TourSeasonApi';
import { useTourSeasonResultApi, Event, EventResult } from '../../api/TourSeasonResultApi';
import { useEffect, useState } from 'react';

import './Leaderboard.css'

interface IEventResultListProps {
    event : Event
}

function EventResultList(props : IEventResultListProps) {
    const event = props.event

    const [visibleRows, setVisibleRows] = useState<Array<EventResult>>(event.results!.slice(0,3))

    const [open, setOpen] = useState<boolean>(false)
    const toggleRows = (on : boolean) => {
        const rows = on ? event.results! : event.results!.slice(0,3)
        setVisibleRows(rows)
        setOpen(on)
    }    


    let pos = 1

    return (
        <div className="leaderboard-event-result-section">
            <h4 className="leaderboard-event-header">{event.name} - {event.date}</h4>
            <div className="leaderboard">
                <Table className="leaderboard-table">
                    <thead>
                        <tr>
                        <td className="leaderboard-fixed">Plac.</td>
                        <td>Spelare</td>
                        <td className="leaderboard-fixed leaderboard-value">Brutto</td>
                        <td className="leaderboard-fixed leaderboard-value">Hcp</td>
                        <td className="leaderboard-fixed leaderboard-value">Netto</td>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            visibleRows.map((r, idx) => {
                                return(
                                    <tr className="leaderboard-row" key={pos}>
                                        <td>{pos++}</td>
                                        <td className="leaderboard-name ">{r.playerName}</td>
                                        <td className="leaderboard-value not-result">{r.bruttoScore}</td>
                                        <td className="leaderboard-value not-result">{r.playHcp}</td>
                                        <td className={"leaderboard-value result "}>{r.score}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
                <Button className="leaderboard-more-result" 
                        onClick={() => toggleRows(!open)} 
                        variant="light">
                            {
                                open ? "färre" : "fler resultat"
                            }
                </Button>

            </div>
        </div>
    )
}


export interface ITourSeasonResults {
    tourSeason : TourSeason
}

export default function TourSeasonResults(props : ITourSeasonResults) {

    const {data, setData, isLoading, hasError, error, setUrl, refreshData} = useTourSeasonResultApi(props.tourSeason.tourSeasonId);
    const {data : participants} = useParticipantApi(props.tourSeason.tourSeasonId)

    // TODO: Find a better way...
    useEffect( () => {
        setUrl(`/tourseason/${props.tourSeason.tourSeasonId}/results`);
    }, [props.tourSeason.tourSeasonId])

    const events = data.filter(e => e.results?.length > 0).sort( (a, b) => {
        if (a.date > b.date) return -1
        if (a.date < b.date) return 1
        return 0
    })

    return (
        <>
            <div className="tourseason-results">
                {
                    events.map( e => 
                        <EventResultList key={e.eventId} event={e} />
                    )
                }
            </div>
        </>
    )
}