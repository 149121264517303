import { useState, useEffect, useContext } from "react"

import { Table, Row, Col, Button } from "react-bootstrap"
import { Modal } from "react-bootstrap"
import { Form } from "react-bootstrap"
import { Trash, Pencil } from "react-bootstrap-icons"
import XfTable, { TXfColumn } from "../XfTable/XfTable"
import { useParticipantApi, Participant, updateParticipantsApi } from "../../api/ParticipantApi"
import { AppContext } from "../../AppContext"
import { confirmDelete } from "../XfConfirmation"
import { Player, usePlayerApi } from "../../api/PlayerApi"
import { TourSeason } from "../../api/TourSeasonApi"

type TParticipantDialogProps = {
    show : boolean,
    onCancel : () => void,
    onOk : (participant : any) => void,
    selectedParticipant : any,
    availablePlayers : Array<Player>,
    tourSeason : TourSeason
}

//
//  Dialog för att administrera participant 
//      - Ny deltagare    
//          - Välja befintlig spelare
//          - Skapa ny spelare
//      - Redigera deltagare    
//
function ParticipantDialog(props : TParticipantDialogProps) {

    const [participant, setParticipant] = useState<Participant>(props.selectedParticipant)
    const sortedPlayers = props.availablePlayers.sort( (a,b) => a.name!.localeCompare(b.name!))

    useEffect( () => {
        setParticipant(props.selectedParticipant)
    }, [props.selectedParticipant])

    const isEdit = participant.participantId != -1 
    const title = isEdit ? "Ändra deltagare" : "Skapa deltagare"

    const ok = () => {
        props.onOk(participant);
    }

    const selectPlayer = (e : any) => {
        const playerId = e.target.value;

        if(playerId == -1) {
            setParticipant({ ...participant, playerId: playerId, playerName : ""})
        } else {
            const playerName = props.availablePlayers.find( (p) => p.playerId == playerId)?.name
            const newPlayerName = playerName == undefined ? "" : playerName
            setParticipant({ ...participant, playerId: +playerId, playerName : newPlayerName})
        }


    }

    return (
        <div className="static-modal">
            <Modal
            animation={false}
            show={props.show}
            onHide={() => props.onCancel()}
            backdrop={true}
            keyboard={true}
            >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {
                        !isEdit ? 
                            <Form.Group className="mb-3" controlId="playerName">
                                <Form.Label>Spelare</Form.Label>
                                <Form.Select onChange={ (e) => selectPlayer(e) }>
                                    <option key={-1} value={-1} >Ny spelare</option>
                                    {
                                        sortedPlayers.map( (p, idx) => (
                                                <option key={idx} value={p.playerId}>{p.name}</option>                                            
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>  
                        : ""
                    }
                    <Form.Group className="mb-3" controlId="playerName">
                        <Form.Label>Namn</Form.Label>
                        <Form.Control type="text" value={participant.playerName!} onChange={ (event) => setParticipant({ ...participant, playerName : event.target.value})}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="playerHcp">
                      <Form.Label>Hcp</Form.Label>
                      <Form.Control type="number" value={participant.hcp} onChange={ 
                            (event) => setParticipant(
                                { ...participant, 
                                    hcp : +event.target.value, 
                                    reducedHcp : +(+event.target.value * props.tourSeason.hcpReduction).toFixed(1),
                                    playHcp : +(+event.target.value * props.tourSeason.hcpReduction).toFixed(0)})} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="reducedHcp">
                      <Form.Label>Reducerat Hcp</Form.Label>
                      <Form.Control type="number" value={participant.reducedHcp} readOnly />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="playHcp">
                      <Form.Label>Spelhcp</Form.Label>
                      <Form.Control type="number" value={participant.playHcp} onChange={ (event) => setParticipant({ ...participant, hcp : +event.target.value})} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="sex">
                      <Form.Label>Kön (M/F)</Form.Label>
                      <Form.Select value={participant.sex} onChange={ (event) => setParticipant({ ...participant, sex : event.target.value})}>
                        <option value="M">Man</option>
                        <option value="F">Kvinna</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="tee">
                      <Form.Label>Tee</Form.Label>
                      <Form.Select value={participant.tee} onChange={ (event) => setParticipant({ ...participant, tee : event.target.value})}>
                        <option value="YELLOW">Gul</option>
                        <option value="RED">Röd</option>
                      </Form.Select>
                    </Form.Group>

                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => props.onCancel()}>Avbryt</Button>
                <Button onClick={() => ok()}>Ok</Button>
            </Modal.Footer>
            </Modal>
        </div>   
  
    )
}
 
//
//  AdminParticipants 
//  
//      Table with edit/delete-buttons and an addItem button. 
//      Also includes a dialog for add/edit
//
export default function AdminParticipants() {

    const context = useContext(AppContext);

    const {data : participants, setData, isLoading, hasError, error, setUrl, refreshData, doCreate, doUpdate, doDelete} = useParticipantApi(context.tourSeason.tourSeasonId);
    const {data : players} = usePlayerApi();

    const emptyParticipant : Participant = { participantId : -1, playerId : -1, playerName : "", hcp : 0.0, tourSeasonId : context.tourSeason.tourSeasonId, sex : "M", tee : "YELLOW", playHcp : 0}
    const [selectedParticipant, setSelectedParticipant] = useState(emptyParticipant)
    const [showDlg, setShowDlg] = useState(false);

    // TODO: Refresh data when tourseason changes
    useEffect( () => {
        updateParticipantsApi(setUrl, context.tourSeason.tourSeasonId);
    }, [context.tourSeason.tourSeasonId])

    const availablePlayers = players.filter( (p) => participants.findIndex( (part) => part.playerId === p.playerId) == -1 )

    const deleteParticipant = async (row : any) => {
        if (await confirmDelete("Radera " + row.playerName)) {
            doDelete(row.participantId)
        }
    }

    const addPlayer = () => {
        setSelectedParticipant(emptyParticipant);
        setShowDlg(true);
    }

    const editPlayer = (row : any) => {
        setSelectedParticipant(row);
        setShowDlg(true);
    }

    const saveParticipant = (participant : Participant) => {
    
        if(participant.participantId == -1) {
            doCreate({ ...participant})
        } else {
            doUpdate(participant.participantId, {...participant})
        }
        setShowDlg(false)
    }

    // 
    //  Column definitions for the table 
    //
    const columns : Array<TXfColumn> = [
        { field : "participantId", name : "Id"},
        { field : "playerName", name : "Spelare"},
        { field : "sex", name : "Kön", format : (v, r) => v == "M" ? "Man" : "Kvinna"},
        { field : "tee", name : "Tee", format: (v, r) => v == "YELLOW" ? "Gul" : "Röd"},
        { field : "hcp", name : "Hcp"},
        { field : "reducedHcp", name : "Red.Hcp" },
        { field : "playHcp", name : "Spel Hcp" },
        { name : "", icon : <Pencil />, onClick : (row : any) =>  editPlayer(row)},
        { name : "", icon : <Trash />, onClick : (row : any) => deleteParticipant(row) }

    ]

    return(
        <div>
            <ParticipantDialog 
                show={showDlg} 
                onCancel={ () => setShowDlg(false)} 
                onOk={ (p) => saveParticipant(p)} 
                selectedParticipant={selectedParticipant} 
                availablePlayers={availablePlayers}
                tourSeason={context.tourSeason} />
            {
                isLoading ? 
                    <div>Loading...</div> :
                    <>
                        <XfTable 
                            data = { participants }
                            columns={columns}
                        />
                        <Button onClick={ addPlayer }>Lägg till spelare</Button>
                    </>
            }
        </div>
    )
}
