import { Form } from "react-bootstrap";
import { Result, useResultApi } from "../../api/ResultApi";
import ParticipantSelector from "./ParticipantSelector";
import { Participant } from "../../api/ParticipantApi";
import { EventParticipant } from "../../api/EventParticipantApi";
import { Trash } from "react-bootstrap-icons";

export interface IEditRoundProps {
    eventParticipant : EventParticipant
    participants : Array<Participant>
    onNewEventParticipant? : (participantId : number) => void
    onChangeEventParticipant? : (eventParticipantId : number, participantId : number) => void,
    onDeleteEventParticipant? : (eventParticipantId : number) => void
}

// TODO Handle change score with some kind of debounce....


export function EditRound(props : IEditRoundProps ) {
    const {data, setData, isLoading, hasError, error, setUrl, refreshData, doCreate, doUpdate, doDelete} = useResultApi(props.eventParticipant.eventParticipantId);

    const isNewParticipant = props.eventParticipant.eventParticipantId === -1 ? true : false

    // Change participant
    const onChangeParticipant = (participantId : number) => {
        if(isNewParticipant) {
            if(props.onNewEventParticipant != undefined)
                props.onNewEventParticipant(participantId) 
        } else {
            if(props.onChangeEventParticipant != undefined) 
                props.onChangeEventParticipant(props.eventParticipant.eventParticipantId, participantId);
        }
    }

    // Delete participant
    const onDeleteEventParticipant = () => {
        if(props.onDeleteEventParticipant != undefined)
            props.onDeleteEventParticipant(props.eventParticipant.eventParticipantId)
    }

    // Manage results
    const onChangeResult = (holeNo : number, scoreText : string ) => {
        const score : number = +scoreText;
        const resultData = data.find(d => d.holeNo === holeNo)!;


        if(resultData == undefined) {
            const newData : Result = { resultId : -1, eventParticipantId : props.eventParticipant.eventParticipantId, score : score, holeNo: holeNo}
            doCreate(newData)
        } else {
            const newData = {...resultData, score : score }
            doUpdate(newData.resultId, newData);    
        }
    }



    // Prepare data
    type TResult = {
        resultId : number,
        holeNo : number,
        score : number | undefined
    }

    const resultArray = Array<TResult>(18)

    for(let i = 0; i<18; i++) {
        const item : TResult = {resultId : -1, holeNo : 0, score : 0}
        const result = data.find(el => el.holeNo == i + 1) 
       
        item.holeNo = i + 1;
        item.resultId = result != undefined ? result.resultId : -1
        item.score = result != undefined ? result.score! : undefined

        resultArray[i] = item
    }

    const columns = [...Array(21).keys()]
    let resultIdx = 0

    let idx = 0;
    let sum9 = 0;
    let sumTot = 0;
// #TODO: Lägg ett mellanrumm efter 9

    return (
        <>
        <tr>
            <td >
                <ParticipantSelector onSelectParticipant={onChangeParticipant} selectedParticipant={props.eventParticipant.participantId} participantList={props.participants} />
            </td>
            <td >
                <div onClick={ () => onDeleteEventParticipant() }><Trash /></div>
            </td>
            {
                data && 
                    columns.map(c => {
                        let component : JSX.Element = <></>
                        
                        switch(c) {
                            case 9:
                            case 19: 
                                component = <td key={idx++}>
                                                <Form.Control   className="w-100 border-top-0 border-start-0 border-end-0 m-auto" 
                                                                tabIndex={-1}
                                                                readOnly
                                                                type="text" 
                                                                value={sum9} />
                                            </td>
                                sum9 = 0
                                break
                            case 20:
                                component = <td key={idx++}>
                                                <Form.Control   className="w-100 border-top-0 border-start-0 border-end-0 m-auto" 
                                                                tabIndex={-1}
                                                                readOnly
                                                                type="text" 
                                                                value={sumTot} />
                                                        </td>
                                break
                            default:
                                const r = resultArray[resultIdx++]
                                const score = r.score ?? ""
                                sum9 += r.score != undefined ? r.score : 0
                                sumTot += r.score != undefined ? r.score : 0
        

                                component = <td className="g-0 result-input-field" key={idx++}>
                                                <Form.Control   className="w-100 border-top-0 border-start-0 border-end-0 m-auto" 
                                                                type="text" 
                                                                value={score} 
                                                                onChange={(e) => { onChangeResult(r.holeNo, e.target.value) }}/>

                                            </td>
                                break

                        }

                        return component
                    })
            }
        </tr>
        </>
    )
}