import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";


export default function StartPage() {
    return (
        <div className="start-page-wrapper">
            <div className="start-page-container">
                <div>
                    <img src="assets/logo_white.svg"/>
                </div>
                <div className="start-page-header-container">
                    <h1 className="start-page-header">Lundatouren</h1>
                </div>
                <div className="start-page-divider">
                    <hr />
                </div>
                <div>
                    <h2>Lunds Akademiska Golfklubb</h2>
                    <h3>Est 2024</h3>
                </div>
                <div>
                    <Button variant="light" href="./about" className="button-read-more">Läs mer</Button>
                </div>
            </div>
        </div>
    )
}