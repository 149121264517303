import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import StartPage from './pages/StartPage';
import LeaderboardPage from './pages/LeaderboardPage';
import ResultsPage from './pages/ResultPage';
import AdminPage from './pages/Admin';
import ParticipantPage from './pages/ParticipantPage';
import AdminRoundsPage from './pages/AdminRoundsPage';
import StatsPage from './pages/StatsPage';
import AboutPage from './pages/AboutPage';

const AppRoutes = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children : [
            {
                path: "",
                element: <StartPage />
            },
            {
                path: "leaderboard",
                element: <LeaderboardPage />
            },
            {
                path: "results",
                element: <ResultsPage />
            },
            {
                path: "about",
                element: <AboutPage />
            },
            {
                path: "stats",
                element: <StatsPage />
            },
            {
                path: "adminrounds",
                element: <AdminRoundsPage />
            },
            {
                path: "admin",
                element: <AdminPage />
            },
            {
                path: "participant/:participantId",
                element: <ParticipantPage />
            }
        ]
    }
]);

export default AppRoutes;