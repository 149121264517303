import useApi, {useSimpleApi} from "./api";
import { LeaderboardResponse } from "./apimodel";

export type { LeaderboardResponse as LeaderboardItem} from "./apimodel";


function createUrl(tourseasonid : number) {
    return `/tourseason/${tourseasonid}/leaderboard`
}

export function updateLeaderboardApi(setUrl : (url : string) => void, tourseasonid : number)  {
    const url = createUrl(tourseasonid);
    setUrl(url)
}

export function useLeaderboardApi(tourseasonid : number) {
    const url = createUrl(tourseasonid);
 
    return useSimpleApi<LeaderboardResponse>(url);
}