import { useParticipantApi } from '../../api/ParticipantApi';
import { TourSeason } from '../../api/TourSeasonApi';

import './Leaderboard.css'
import { Table } from 'react-bootstrap';
import { useTourSeasonResultApi } from '../../api/TourSeasonResultApi';
import { useEffect } from 'react';


export interface IPlacementStatLeaderboard {
    tourSeason : TourSeason
}

export default function PlacementStatLeaderboard(props : IPlacementStatLeaderboard) {

    const {data, setData, isLoading, hasError, error, setUrl, refreshData} = useTourSeasonResultApi(props.tourSeason.tourSeasonId);
    const {data : participants} = useParticipantApi(props.tourSeason.tourSeasonId)

    // TODO: Find a better way...
    useEffect( () => {
        setUrl(`/tourseason/${props.tourSeason.tourSeasonId}/results`);
    }, [props.tourSeason.tourSeasonId])

    type TRowData = {
        playerName : string,
        wins : number, 
        seconds : number, 
        thirds : number
    }

    const rowData : Array<TRowData> = participants.map( p => {
        const wins = data.reduce( (w : number, e) => e.results[0]?.participantId == p.participantId ? w + 1 : w , 0)
        const seconds = data.reduce( (w : number, e) => e.results[1]?.participantId == p.participantId ? w + 1 : w , 0)
        const thirds = data.reduce( (w : number, e) => e.results[2]?.participantId == p.participantId ? w + 1 : w , 0)
        const row = { playerName : p.playerName!, wins : wins, seconds : seconds, thirds : thirds}

        return row
    }).sort( (a : TRowData, b:TRowData) => {
        const aVal = a.wins * 100 + a.seconds * 10 + a.thirds
        const bVal = b.wins * 100 + b.seconds * 10 + b.thirds
        
        return bVal - aVal
    });

    // const rowData : Array<TRowData> = participants.map( (pa) => {
    //     const findEagles = data.find( d => d.participantId == pa.participantId && d.scoreToPar == -2)
    //     const eagles = findEagles != undefined ? findEagles.count : 0

    //     const findBirdies = data.find( d => d.participantId == pa.participantId && d.scoreToPar == -1)
    //     const birdies = findBirdies != undefined ? findBirdies.count : 0

    //     const findPars = data.find( d => d.participantId == pa.participantId && d.scoreToPar == 0)
    //     const pars = findPars != undefined ? findPars.count : 0

    //     const row = { playerName : pa.playerName!, eagles : eagles, birdies : birdies, pars : pars}

    //     return row
    // }).sort( (a : TRowData,b : TRowData) => {
    //     if(sortBy == 'eagles') 
    //         return b.eagles - a.eagles
        
    //     if(sortBy == 'birdies') 
    //         return b.birdies - a.birdies

    //     return b.pars - a.pars
    // })

    let pos = 1

    const onSortBy = (column : string) => {
        
    }



    return (
        <>
            <div className="leaderboard">
                <Table className="leaderboard-table">
                    <thead>
                        <tr>
                        <td className="leaderboard-fixed">Plac.</td>
                        <td>Spelare</td>
                        <td className="leaderboard-fixed leaderboard-value " onClick={ () => onSortBy('eagles') }>Wins</td>
                        <td className="leaderboard-fixed leaderboard-value " onClick={ () => onSortBy('birdies')} >2nd</td>
                        <td className="leaderboard-fixed leaderboard-value " onClick={ () => onSortBy('pars')} >3rd</td>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            rowData.map((r, idx) => {
                                return(
                                    <tr className="leaderboard-row" key={pos}>
                                        <td>{pos++}</td>
                                        <td className="leaderboard-name ">{r.playerName}</td>
                                        <td className="leaderboard-value not-result">{r.wins}</td>
                                        <td className="leaderboard-value not-result">{r.seconds}</td>
                                        <td className={"leaderboard-value not-result "}>{r.thirds}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}