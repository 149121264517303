import { Collapse, Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { TourSeason } from "../../api/TourSeasonApi"
import { useLeaderboardApi, LeaderboardItem, updateLeaderboardApi } from "../../api/LeaderboardApi"
import './Leaderboard.css'
import { useEffect, useState } from "react"
import ParticipantStats from "./ParticipantStats"
import { Link } from "react-bootstrap-icons"
import { CaretDownFill, CaretUpFill, Dash } from "react-bootstrap-icons"
import { updateParticipantRoundResultsApi, useParticipantRoundResultsApi } from "../../api/ParticipantRoundResultApi"


//#region Eclectic
interface ILeaderboardEclecticRowProps {
    position : number
    previousPosition : number
    data : LeaderboardItem
    onSelectParticipant? : (participantId : number) => void 
}

function LeaderboardEclecticRow(props : ILeaderboardEclecticRowProps) {
    const scoreClassName = props.data.bestScore < 72 ? " result-under-par" : ""
    const rowData = props.data
    const [expandRow, setExpandRow] = useState<boolean>(false)

    const onSelectParticipant = (participantId : number) => {
        if(props.onSelectParticipant) props.onSelectParticipant(participantId)  
    } 

    const onExpandRow = (participantId : number) => {
        setExpandRow(!expandRow)
    }

    // Fix +-hcp so normal -hcp is shown without sign and + is shown with +
    const reducedHcpText = rowData.reducedHcp < 0 ? "+" + (-1 * rowData.reducedHcp) : rowData.reducedHcp

    const icon = props.position -  props.previousPosition < 0 ? 
                    <CaretUpFill style={{color : "green", width:"10px"}} /> : ( 
                        props.position - props.previousPosition > 0 ? <CaretDownFill style={{color : "red", width:"10px"}} /> : <Dash />)
    return (
        <>
            <tr className="leaderboard-row" onClick={ () => { onExpandRow(rowData.participantId) } }>
                <td className="leaderboard-value">{props.position}</td>
                <td className="leaderboard-value leaderboard-col-icon">{icon}</td>
                <td className="leaderboard-name ">{rowData.name}</td>
                <td className="leaderboard-value not-result">{rowData.bestScoreBrutto}</td>
                <td className="leaderboard-value not-result">{reducedHcpText}</td>
                <td className={"leaderboard-value result " + scoreClassName}>{rowData.bestScore}</td>
                <td className="leaderboard-value not-result">{rowData.numberOfEvents}</td>
            </tr>
            {
                expandRow ? 
                    <tr className="leaderboard-row">
                        <td colSpan={7} className="leaderboard-expanded-section">
                            <div className="leaderboard-expanded-header" onClick={() => onSelectParticipant(rowData.participantId)}>Visa statistik <Link /></div>
                            <ParticipantStats showHeader={false} showStats={false} participantId={props.data.participantId} tourSeasonId={1} />
                        </td>
                    </tr>
                : ""
            }
        </>
    )
}

export interface ILeaderboardEclecticProps {
    tourSeason : TourSeason
    tourSeasonId : number
}

function LeaderboardEclectic(props : ILeaderboardEclecticProps) {

    const navigate = useNavigate()
    const {data, setData, isLoading, hasError, error, setUrl, refreshData} = useLeaderboardApi(props.tourSeason.tourSeasonId);

    // TODO: Refresh data when tourseason changes
    useEffect( () => {
        updateLeaderboardApi(setUrl, props.tourSeason.tourSeasonId);
    }, [props.tourSeason.tourSeasonId])

    let pos : number = 1;
    const leaderboardData = data.sort( (a,b) => (a.bestScore - b.bestScore) )

    const leaderboardDataPrev = data.slice().sort( (a,b) => (a.bestScorePrev ?? 999) - (b.bestScorePrev ?? 999))

    const onSelectParticipant = (participantId : number) => navigate("/participant/" + participantId)

    return (
        <div  className="leaderboard">
            <Table hover className="leaderboard-table">
                <thead>
                    <tr>
                        <td className="leaderboard-fixed leaderboard-value leaderboard-col-pos">#</td>
                        <td className="leaderboard-fixed leaderboard-value leaderboard-col-icon"></td>
                        <td>Spelare</td>
                        <td className="leaderboard-fixed leaderboard-value">Brutto</td>
                        <td className="leaderboard-fixed leaderboard-value">Hcp</td>
                        <td className="leaderboard-fixed leaderboard-value">Netto</td>
                        <td className="leaderboard-fixed leaderboard-value">Omg.</td>
                    </tr>
                </thead>
                <tbody>
                    {   
                        leaderboardData.map( r => {
                            const prevIdx = leaderboardDataPrev.findIndex( p => p.participantId == r.participantId)
                            const prevPos = prevIdx == -1 ? 99 : prevIdx + 1

                            return (
                                <LeaderboardEclecticRow key={pos++} position={pos} previousPosition={prevPos} data={r} onSelectParticipant={onSelectParticipant} />
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}
//#endregion


//#region BestRounds

//#region Eclectic

interface ILeaderboardBestRoundsItem {
    participantId : number;
    name : string;
    hcp : number;
    reducedHcp : number
    playHcp : number
    brutto : number
    netto : number 
    roundResults : Array<number>
    numberOfEvents : number
}

interface ILeaderboardBestRoundsRowProps {
    position : number
    previousPosition : number
    data : ILeaderboardBestRoundsItem
    onSelectParticipant? : (participantId : number) => void 
}

function LeaderboardBestRoundsRow(props : ILeaderboardBestRoundsRowProps) {
    // const scoreClassName = props.data.bestScore < 72 ? " result-under-par" : ""
    const rowData = props.data
    // const [expandRow, setExpandRow] = useState<boolean>(false)

    // const onSelectParticipant = (participantId : number) => {
    //     if(props.onSelectParticipant) props.onSelectParticipant(participantId)  
    // } 

    // const onExpandRow = (participantId : number) => {
    //     setExpandRow(!expandRow)
    // }

    // Fix +-hcp so normal -hcp is shown without sign and + is shown with +
    const reducedHcpText = rowData.reducedHcp < 0 ? "+" + (-1 * rowData.reducedHcp) : rowData.reducedHcp

    // const icon = props.position -  props.previousPosition < 0 ? 
    //                 <CaretUpFill style={{color : "green", width:"10px"}} /> : ( 
    //                     props.position - props.previousPosition > 0 ? <CaretDownFill style={{color : "red", width:"10px"}} /> : <Dash />)

    const r1 = rowData.roundResults.length > 0 ? rowData.roundResults[0] : "-"
    const r2 = rowData.roundResults.length > 1 ? rowData.roundResults[1] : "-"
    const r3 = rowData.roundResults.length > 2 ? rowData.roundResults[2] : "-"
    
    return (
        <>
            <tr className="leaderboard-row" onClick={ () => { /*onExpandRow(rowData.participantId)*/ } }>
                <td className="leaderboard-value">{props.position}</td>
                <td className="leaderboard-name ">{rowData.name}</td>
                <td className="leaderboard-value not-result">{rowData.playHcp}</td>
                <td className="leaderboard-value not-result">{r1 == 0 ? "-" : r1}</td>
                <td className="leaderboard-value not-result">{r2 == 0 ? "-" : r2}</td>
                <td className="leaderboard-value not-result ">{r3 == 0 ? "-" : r3}</td>
                <td className="leaderboard-value result">{rowData.netto}</td>
            </tr>
            {/* {
                expandRow ? 
                    <tr className="leaderboard-row">
                        <td colSpan={7} className="leaderboard-expanded-section">
                            <div className="leaderboard-expanded-header" onClick={() => onSelectParticipant(rowData.participantId)}>Visa statistik <Link /></div>
                            <ParticipantStats showHeader={false} showStats={false} participantId={props.data.participantId} tourSeasonId={1} />
                        </td>
                    </tr>
                : ""
            } */}
        </>
    )
}



interface ILeaderboardBestRoundsProps {
    tourSeason : TourSeason
}

function LeaderboardBestRounds(props : ILeaderboardBestRoundsProps) {
    const navigate = useNavigate()
    const {data, setData, isLoading, hasError, error, setUrl, refreshData} = useParticipantRoundResultsApi(props.tourSeason.tourSeasonId);

    useEffect( () => {
        updateParticipantRoundResultsApi(setUrl, props.tourSeason.tourSeasonId);
    }, [props.tourSeason.tourSeasonId])

    const participantIds = data.map( (item) => item.participantId ).filter( (v, idx, cv) => cv.indexOf(v) === idx)

    let maxRounds = 0

    const leaderboardData = participantIds.map( (pid) => {
        const rounds = data.filter( (d) => d.participantId === pid).sort( (a, b) => a.netto - b.netto);

        if(rounds.length > maxRounds) maxRounds = rounds.length

        const threeBest : Array<number> = []
        let brutto = 0
        let netto = 0

        rounds.forEach( (r, idx) => {
            brutto += r.brutto
            netto += r.brutto - r.playHcp
            if(idx < 2)
                threeBest.push(r.netto)
        })

        const rest = 3 - threeBest.length
        for(let i = 0; i<rest ; i++) {
            threeBest.push(0)
        }

        const name = rounds[0].name;
        const hcp = rounds[0].hcp;
        const playHcp = rounds[0].playHcp
        const reducedHcp = rounds[0].reducedHcp

        const item : ILeaderboardBestRoundsItem = { participantId : pid, name : name, hcp : hcp, reducedHcp : reducedHcp, playHcp : playHcp, brutto : brutto, netto : netto, roundResults : threeBest, numberOfEvents : rounds.length }

        return item

    }).sort( (a,b) => {
        return (a.netto + (maxRounds - a.numberOfEvents) * 1000) - (b.netto + (maxRounds - b.numberOfEvents) * 2000)
    })



    let pos : number = 1;


    // const onSelectParticipant = (participantId : number) => navigate("/participant/" + participantId)

    return (
        <div  className="leaderboard">
            <Table hover className="leaderboard-table">
                <thead>
                    <tr>
                        <td className="leaderboard-fixed leaderboard-value leaderboard-col-pos">#</td>
                        <td>Spelare</td>
                        <td className="leaderboard-fixed leaderboard-value">Hcp</td>
                        <td className="leaderboard-fixed leaderboard-value">R1</td>
                        <td className="leaderboard-fixed leaderboard-value">R2</td>
                        <td className="leaderboard-fixed leaderboard-value">R3</td>
                        <td className="leaderboard-fixed leaderboard-value">Tot</td>
                    </tr>
                </thead>
                <tbody>
                    {   
                        leaderboardData.map( r => {

                            return (
                                <LeaderboardBestRoundsRow key={pos++} position={pos} previousPosition={pos} data={r} onSelectParticipant={ () => null } />
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}

//#endregion


export interface ILeaderboardProps {
    tourSeason : TourSeason
    tourSeasonId : number
}

export default function Leaderboard(props : ILeaderboardProps) {

    let component = <></>

    if(props.tourSeason.gameTypeId === 1) {
        component = <LeaderboardEclectic tourSeason={props.tourSeason} tourSeasonId={props.tourSeasonId} />
    } else if (props.tourSeason.gameTypeId === 2) {
        component = <LeaderboardBestRounds tourSeason={props.tourSeason} />
    }

    return (
        <>
            {component}
        </>
    )

}
