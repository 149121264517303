import useApi from "./api";
import { ParticipantResponse } from "./apimodel";

export type { ParticipantResponse as Participant} from "./apimodel";

function createUrl(tourseasonid : number) {
    return `/tourseason/${tourseasonid}/participants`
}

export function updateParticipantsApi(setUrl : (url : string) => void, tourseasonid : number)  {
    const url = createUrl(tourseasonid);
    setUrl(url)
}

export function useParticipantApi(tourseasonid : number) {

    const url = createUrl(tourseasonid);
    return useApi<ParticipantResponse>(url, "participantId");
}