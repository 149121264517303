import { Accordion } from "react-bootstrap";
import AdminPlayers from "../components/pages/AdminPlayers";
import AdminParticipants from "../components/pages/AdminParticipants";
import { useContext } from "react";
import { AppContext } from "../AppContext";

export default function AdminPage() {

    const context = useContext(AppContext);

    return (
        <>
            <h3>Administration - {context.tourSeason.name}</h3>
            <Accordion defaultActiveKey={"1"}>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Deltagare</Accordion.Header>
                    <Accordion.Body>
                        <AdminParticipants />
                    </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="2">
                    <Accordion.Header>Säsonger</Accordion.Header>
                    <Accordion.Body>
                        Kommer snart
                    </Accordion.Body>
                </Accordion.Item> */}
            </Accordion>            
        </>
    )
}