import { useParticipantApi } from '../../api/ParticipantApi';
import { TourSeason } from '../../api/TourSeasonApi';
import { useEffect, useState } from 'react';

import './Leaderboard.css'
import { useScoringStatsApi } from '../../api/ScoringStatsApi';
import { Table } from 'react-bootstrap';


export interface IScoringStatsLeaderboard {
    tourSeason : TourSeason
}

export default function ScoringStatsLeaderboard(props : IScoringStatsLeaderboard) {

    const {data, setData, isLoading, hasError, error, setUrl, refreshData} = useScoringStatsApi(props.tourSeason.tourSeasonId);
    const {data : participants} = useParticipantApi(props.tourSeason.tourSeasonId)
    const [sortBy, setSortBy] = useState('eagles')

    // TODO: Find a better way...
    useEffect( () => {
        setUrl(`/tourseason/${props.tourSeason.tourSeasonId}/stats/scoretopar`);
    }, [props.tourSeason.tourSeasonId])

    type TRowData = {
        playerName : string,
        eagles : number, 
        birdies : number, 
        pars : number
    }

    const rowData : Array<TRowData> = participants.map( (pa) => {
        const findEagles = data.find( d => d.participantId == pa.participantId && d.scoreToPar == -2)
        const eagles = findEagles != undefined ? findEagles.count : 0

        const findBirdies = data.find( d => d.participantId == pa.participantId && d.scoreToPar == -1)
        const birdies = findBirdies != undefined ? findBirdies.count : 0

        const findPars = data.find( d => d.participantId == pa.participantId && d.scoreToPar == 0)
        const pars = findPars != undefined ? findPars.count : 0

        const row = { playerName : pa.playerName!, eagles : eagles, birdies : birdies, pars : pars}

        return row
    }).sort( (a : TRowData,b : TRowData) => {
        if(sortBy == 'eagles') 
            return (b.eagles * 10000 + b.birdies * 100 + b.pars) - (a.eagles * 10000 + a.birdies * 100 + a.pars)
        
        if(sortBy == 'birdies') 
            return b.birdies - a.birdies

        return b.pars - a.pars
    })

    let pos = 1

    const onSortBy = (column : string) => {
        setSortBy(column);
    }



    return (
        <>
            <div className="leaderboard">
                <Table className="leaderboard-table">
                    <thead>
                        <tr>
                        <td className="leaderboard-fixed">Plac.</td>
                        <td>Spelare</td>
                        <td className="leaderboard-fixed leaderboard-value selectable" onClick={ () => onSortBy('eagles') }>Eagles</td>
                        <td className="leaderboard-fixed leaderboard-value selectable" onClick={ () => onSortBy('birdies')} >Birdies</td>
                        <td className="leaderboard-fixed leaderboard-value selectable" onClick={ () => onSortBy('pars')} >Pars</td>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            rowData.map((r, idx) => {
                                return(
                                    <tr className="leaderboard-row" key={pos}>
                                        <td>{pos++}</td>
                                        <td className="leaderboard-name ">{r.playerName}</td>
                                        <td className="leaderboard-value not-result">{r.eagles}</td>
                                        <td className="leaderboard-value not-result">{r.birdies}</td>
                                        <td className={"leaderboard-value not-result "}>{r.pars}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}