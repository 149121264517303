import useApi from "./api";
import { EventResponse } from "./apimodel";

export type { EventResponse as Event} from "./apimodel";

function createUrl(tourseasonid : number) {
    return `/tourseason/${tourseasonid}/event`
}

export function updateEventApi(setUrl : (url : string) => void, tourseasonid : number)  {
    const url = createUrl(tourseasonid);
    setUrl(url)
}

export function useEventApi(tourseasonid : number) {

    const url = createUrl(tourseasonid);
    return useApi<EventResponse>(url, "eventId");
}