import useApi, {useSimpleApi} from "./api";
import { ParticipantRoundResultResponse } from "./apimodel";

export type { ParticipantRoundResultResponse as ParticipantRoundResult} from "./apimodel";


function createUrl(tourseasonid : number) {
    return `/tourseason/${tourseasonid}/participantroundresults`
}

export function updateParticipantRoundResultsApi(setUrl : (url : string) => void, tourseasonid : number)  {
    const url = createUrl(tourseasonid);
    setUrl(url)
}

export function useParticipantRoundResultsApi(tourseasonid : number) {
    const url = createUrl(tourseasonid);
 
    return useSimpleApi<ParticipantRoundResultResponse>(url);
}