import { useState, useEffect, useCallback } from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";


// Base configuration used for all axios calls
var axiosBaseConfig = {
    baseURL : process.env.REACT_APP_API 
}

// The axios instance to use
export const api = axios.create(axiosBaseConfig);

//
//  Hook to call Api
//  All manupulating api-calls will update data to reflect changes
//
function useApi<T>(initialUrl : string, identityField : keyof T, options? : AxiosRequestConfig ) {
    const [url, setUrl] = useState(initialUrl);
    const [data, setData] = useState<Array<T>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);
    const [error, setError] = useState<string|null>(null);
    const [refresh, setRefresh] = useState<number>(0);

    // Method to force re-read from the api (trigger another GET)
    const refreshData = () => {
        setRefresh(refresh+1)
    };

    // Helper to make url using an ID as parameter
    const makeUrl = (url : string, id : number ) => {
      var retUrl = url;
      retUrl = retUrl.endsWith("/") ? retUrl : retUrl + "/"
      retUrl = retUrl + id.toString();
      return retUrl;
  }

    // Getter
    useEffect( () => {
        const doFetch = async () => {
            setIsLoading(true);
            try 
            {
                const response : AxiosResponse<Array<T>> = await api(url, options);
                var result = await response.data;
                if (!Array.isArray(result))
                  result = [result];

                if (response.status == 200) {
                    setData(result);
                } else {
                    setHasError(true);
                    setError(response.statusText)
                }
            }
            catch (error) {
                setHasError(true);
                const err : string = String(error);
                setError(err);
            }
            finally {
                setIsLoading(false);
            }
        };

        doFetch();

    }, [refresh, url]);


    // Post (Create)
    const doCreate = useCallback(async (requestData : T) => {
      setIsLoading(true);
      setHasError(false);
      setError(null);

      try {
        const response : AxiosResponse<T> = await axios.post(url, requestData, axiosBaseConfig);
        const newData : Array<T> = [...data!, response.data]
        setData(newData);
        return response.data;
      } catch (err : any) {
        setHasError(true)
        setError(err);
        return null;
      } finally {
        setIsLoading(false);
      }

    }, [data]);

    // put (Update)
    const doUpdate = useCallback(async (id : number, requestData : T) => {

      const putUrl = makeUrl(url, id)

      setIsLoading(true);
      setHasError(false);
      setError(null);
      try {
        const response : AxiosResponse<T> = await axios.put(putUrl, requestData, axiosBaseConfig);
        const newData : Array<T> = data.map(x => x[identityField] === response.data[identityField] ? response.data : x)
        setData(newData);

        return response.data;
      } catch (err : any) {
        setHasError(true)
        setError(err);
      } finally {
        setIsLoading(false);
      }
    }, [data]);;

    // DELETE
    const doDelete = useCallback(async (id : number) => {

      const deleteUrl = makeUrl(url, id);

      setIsLoading(true);
      setHasError(false);
      setError(null);
      try {
        const response : AxiosResponse<boolean> = await axios.delete(deleteUrl, axiosBaseConfig);
        const newData : Array<T> = data.filter(x => x[identityField] !== id) 
        setData(newData)
        return true
        //setData(response.data);
      } catch (err : any) {
        setHasError(true)
        setError(err);
        return false
      } finally {
        setIsLoading(false);
      }
      setIsLoading(false);
    }, [data]);;

    return {data, setData, isLoading, hasError, error, setUrl, refreshData, doCreate, doUpdate, doDelete}
}

//
//  Simple Hook to call get-Api
//
export function useSimpleApi<T>(initialUrl : string, options? : AxiosRequestConfig ) {
  const [url, setUrl] = useState(initialUrl);
  const [data, setData] = useState<Array<T>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [error, setError] = useState<string|null>(null);
  const [refresh, setRefresh] = useState<number>(0);
  // Method to force re-read from the api (trigger another GET)
  const refreshData = () => {
      setRefresh(refresh+1)
  };

  // Getter
  useEffect( () => {

      const doFetch = async () => {
          setIsLoading(true);
          try 
          {
              const response : AxiosResponse<Array<T>> = await api(url, options);
              var result = await response.data;
              if (!Array.isArray(result))
                result = [result];

              if (response.status == 200) {
                  setData(result);
              } else {
                  setHasError(true);
                  setError(response.statusText)
              }
          }
          catch (error) {
              setHasError(true);
              const err : string = String(error);
              setError(err);
          }
          finally {
              setIsLoading(false);
          }
      };

      doFetch();

  }, [refresh, url]);


  return {data, setData, isLoading, hasError, error, setUrl, refreshData}
}

//
//  Simple Hook to call post-Api
//
export async function doPostRequest<TResp, TReq>(initialUrl : string, requestData : TReq) {

  const url = axiosBaseConfig.baseURL + initialUrl
  try 
  {
    const response : AxiosResponse<TResp> = await axios.post(url, requestData);
    var result = await response.data;
  
    if (response.status == 200) {
      return result
    } else {
      return null
    }
  
  }
  catch 
  {
    return null
  }
}


export default useApi;