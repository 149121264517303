
export default function AboutPage() {
    return (
        <>

            <div className="about-page-container">
                <h2>Välkommen till Lundatouren!</h2> 
                <p>
                    Vi vet att golf är ett spel av precision och strategi, men det betyder inte att vi inte kan ha lite roligt längs vägen. Här hittar du allt du behöver veta för vara med på den numera episka Lundatouren. Vi kör över hela säsongen och det finns inga som helst krav att du ska vara med varje gång. 
                    Vi vill göra det enkelt för dig att komma i kontakt med oss. Oavsett om du har frågor om Lundatouren, 
                    behöver hjälp med anmälan, eller har andra funderingar, finns vi här för att hjälpa dig.
                </p>
                <p>
                    <h4>Info och regler:</h4>
                    <p>
                        Tävlingen spelas som en eclectic (bästa resultat per hål över säsongen) på LAGK med en final i slutet av september. 
                        Endast officiella tävlingar räknas och spelschema presenteras i början av säsongen. Vinnare är den med lägst nettoscore och varje spelare spelar på 
                        3/4 delar av sitt hcp (det som ligger på golf.se i början av säsongen, hcp för tävlingen ändras inte under säsongen). Noterar vi konstigheter med HCP så utesluts man utan pardon från touren.
                    </p>
                    <p>
                        Tävlingsavgift är 40kr per spelomgång varav 20kr går till eclecticpotten och 20kr till pott för omgångens tävling. Potterna fördelas 50/30/20 till de tre första platserna i respektive tävling.
                    </p>
                    <p>
                        Gamebook ett krav. Saknar du appen laddar du hem den här <a href="https://golfgamebook.go.link/profile/237429?adj_t=1cjxuz4x">Golf Gamebook</a>.
                    </p>

                </p>
                <p>
                    Tävlingarna spelas på söndagmorgon och anmälan sker senast torsdagen före. Anmälan sker via mail till tävlingsledningen (se mail nedan) samt en betalning via swish. Vid avanmälan går insatsen till potten.
                </p>
                <p>
                    Vi reserverar oss för att dessa förutsättningar kan justeras med kort varsel. 
                </p>

                <h4>Kontakt</h4> 
                <p>Så här når du oss:<br />
                    E-post: <a href="mailto: richard.bostam@icloud.com">richard.bostam@icloud.com</a> (för snabba svar på dina frågor)<br />
                    Instagram: <a href="https://www.instagram.com/richard_bostam">The Almost Famous Golfer</a>
                </p>
                <p>
                    Om du vill vara med så skicka ett mail med ditt golf-id till ovanstående.
                </p>
                <p>
                    Vi ser fram emot att höra från dig och hjälpa dig med allt du behöver inför Lundatouren!
                </p>
            </div>



        </>
    )
}