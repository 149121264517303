import { useParticipantStatApi, ParticipantStat } from "../../api/ParticipantStatsApi";
import { RoundTable } from "./ViewRound";
import './ParticipantStats.css'
import { useCourseApi } from "../../api/CourseApi";
import { ParticipantStatEventResponse, ParticipantStatResultResponse } from "../../api/apimodel";

interface IPlayerStatProps {
    header : string;
    value : string | number;
}

function PlayerStat(props : IPlayerStatProps) {
    return (
        <div className="player-stat">
            <div className="player-stat-header">{props.header}</div>
            <div className="player-stat-value">{props.value}</div>
        </div>
    )
}

export interface IParticipantStatsProps {
    participantId : number,
    tourSeasonId : number,
    showHeader? : boolean,
    showStats? : boolean
}

function getRoundScores(events: ParticipantStatEventResponse[]) {
    let scores = Array()
    for(let e of events) {
        let score = 0
        for(let r of e.result) {
            score += r.score
        }
        scores.push(score)
    }
    return scores
}

function calculateStats(events :ParticipantStatEventResponse[], holePar : Array<number>) {
    let hios = 0
    let eagles = 0
    let birdies = 0
    let pars = 0
    let bogeys = 0
    let dbogeys = 0
    let worses = 0

    for(const e of events) {

        let ix = 0

        for(const r of e.result) {
            const par = holePar[ix++]

            if(r.score == 1) { hios++; }
            if(r.score - par == -2) { eagles++;  }
            if(r.score - par == -1) { birdies++;  }
            if(r.score - par == 0) { pars++;  }
            if(r.score - par == 1) { bogeys++;  }
            if(r.score - par == 2) { dbogeys++;  }
            if(r.score - par > 2) { worses++;  }
        }
    }

    return { hios : hios, eagles : eagles, birdies : birdies, pars : pars, bogeys : bogeys, dbogeys : dbogeys, worses : worses}

}

export default function ParticipantStats(props : IParticipantStatsProps) {  

    const { data } = useParticipantStatApi(props.tourSeasonId, props.participantId);    
    const { data : course } = useCourseApi(1); 

    let stat : ParticipantStat | undefined = data != undefined ? data[0] : undefined;

    const showHeader = props.showHeader != undefined ? props.showHeader : true
    const showStats = props.showStats != undefined ? props.showStats : true

    const playerName = stat?.playerName ?? "";
    const playerHcp = stat?.reducedHcp ?? 0.0;

    const bestRound = stat?.bestRound ?? []
    const bestRoundTot : number = stat?.bestRound?.map(r => r.score).reduce( (r, s) =>  r! + s!) ?? 0

    const score = bestRound?.map(br => br.score!) ?? [];
    
    const holes = course ? (course.length > 0 ? course[0].holes.sort( (a,b) => a.holeNo - b.holeNo) : []) : []
    const holePar : Array<number> = holes.map( h => h.par)
    
    const numberOfEvents = stat ? stat.events.filter( e => e.participated == 1).length : 0

    const participatedEvents = stat ? stat.events.filter(e => e.participated == 1) : []

    const roundScores : Array<number> = participatedEvents.map( pe => pe.result.reduce( (acc, val) => acc + val.score, 0))

    const lowestScore = roundScores.length > 0 ? roundScores.sort( (a,b) => a - b)[0] : 0
    const averageScore = (roundScores.length > 0 ? roundScores.reduce( (acc, val) => acc + val, 0) / roundScores.length : 0).toFixed(1)

    const scoreStats = calculateStats(participatedEvents, holePar)

    return (
        <>
            <div>
                {
                    showHeader ? <h4>{playerName} - ({bestRoundTot - playerHcp})</h4> : ""
                }
                <div className="player-stat-frame">
                    {
                        showStats ? 

                            <div className="player-stats">
                                <div className="player-stat-group">
                                    <PlayerStat header="Hcp" value={ playerHcp } />
                                    <PlayerStat header="Antal   " value={ numberOfEvents } />
                                    <PlayerStat header="Eclec." value={ bestRoundTot } />
                                    <PlayerStat header="Lägst" value={ lowestScore } />
                                    <PlayerStat header="Snitt" value={ averageScore } />   
                                </div>
                                <div className="player-stat-group">
                                    <PlayerStat header="HIO" value={ scoreStats.hios } />
                                    <PlayerStat header="Eagle" value={ scoreStats.eagles } />
                                    <PlayerStat header="Birdie" value={ scoreStats.birdies } />
                                    <PlayerStat header="Par" value={ scoreStats.pars } />
                                    <PlayerStat header="Bog" value={ scoreStats.bogeys } />
                                    <PlayerStat header="D.Bog" value={ scoreStats.dbogeys } />
                                    <PlayerStat header="Sämre" value={ scoreStats.worses } />
                                </div>
                            </div>
                        : ""
                    }
                    <div className="player-stat-eclictic-table">
                        <RoundTable score={score} holePar={holePar} />
                    </div>
            </div>
        </div>

        </>
    )
}