import { useEventParticipantApi, EventParticipant } from "../../api/EventParticipantApi";
import { EditRound } from "./EditRound";
import { Participant } from "../../api/ParticipantApi";
import { Table } from "react-bootstrap";
import './EditEvent.css'

export interface IEditEventProps {
    eventId : number
    participants : Array<Participant>
}

export default function EditEvent(props : IEditEventProps) {
    const {data, setData, isLoading, hasError, error, setUrl, refreshData, doCreate, doUpdate, doDelete} = useEventParticipantApi(props.eventId);
    // Data
    const newParticipant : EventParticipant = { eventParticipantId : -1, participantId : -1, eventId : props.eventId }

    //const holeNo = [...Array(18).keys()]
    const columns = [...Array(21).keys()]
    const pIds = data.map(p => p.participantId)
    const selectableParticipants = props.participants.filter( p => ! pIds.includes(p.participantId) )

    // Events
    const onNewEventParticipant = (participantId : number) => {
        const newItem : EventParticipant = { eventParticipantId : -1, eventId : props.eventId, participantId : participantId}
        doCreate(newItem)
    }

    const onChangeEventParticipant = (eventParticipantId : number, participantId : number) => {
        const newItem : EventParticipant = {eventParticipantId: eventParticipantId, eventId : props.eventId, participantId : participantId} 
        doUpdate(eventParticipantId, newItem)
    }

    const onDeleteEventParticipant = (eventParticipantId : number) => {
        doDelete(eventParticipantId)
    }

    // Styles
    const playerStyle = { minWidth: "200px" }

    const playerCount = data.length
    let key = 0
    let holeNo = 1

    return (
        <>
            <div className="editevent-number-of-players">Antal spelare: {playerCount}</div>
            <Table bordered size="sm" striped="columns">
                <thead>
                    <tr>
                        <td className="" style={playerStyle}>
                            <div>Spelare</div>
                        </td>
                        <td>

                        </td>
                        {
                            columns.map( c => {
                                let component : JSX.Element = <></>
                                if(c == 9) {
                                    // Sum UT
                                    component = <td className="g-0 result-input-field" key={key++}><div className="text-center">UT</div></td>
                                } else if(c == 19) {
                                    // SUM IN
                                    component = <td className="g-0 result-input-field" key={key++}><div className="text-center">IN</div></td>
                                } else if(c == 20) {
                                    // TOT
                                    component = <td className="g-0 result-input-field" key={key++}><div className="text-center">TOT</div></td>
                                } else {
                                    component = <td key={key++} className="g-0 result-input-field"><div className="text-center">{holeNo++}</div></td> 
                                }

                                return component
                            })
                        }
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {
                        data && 
                            data.map(ep => {
                                const meP = props.participants.find( p => p.participantId === ep.participantId)
                                const tmp = [...selectableParticipants]
                                meP != undefined && tmp.push(meP)

                                return <EditRound 
                                            key={key++} 
                                            eventParticipant={ep} 
                                            participants={tmp} 
                                            onDeleteEventParticipant={onDeleteEventParticipant}
                                            onChangeEventParticipant={onChangeEventParticipant}/>
                                }
                            )
                    }
                    
                    <EditRound key={0} eventParticipant={newParticipant} participants={selectableParticipants} onNewEventParticipant={onNewEventParticipant}/>
                </tbody>
            </Table>
        </>
    )
}
