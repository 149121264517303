import useApi from "./api";
import { ResultResponse} from "./apimodel";

export type { ResultResponse as Result} from "./apimodel";

export function useResultApi(eventparticipantid : number) {

    eventparticipantid = eventparticipantid > 0 ? eventparticipantid : 0

    const url = `/eventparticipant/${eventparticipantid}/results`;
    return useApi<ResultResponse>(url, "resultId");     
}